<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :customFiltersShow="true",
          :zoneFilterShow="zone_agency_filter"
          :agencyFilterShow="zone_agency_filter"
          :locationFilterShow="true",
          :roleFilterShow="true",
          :processFilterShow="true",
          :supervisorFilterShow="true",
          :dateRangeFilterShow="true",
          :locationDefaultValue="true",
          @changeFilter="updateFilter",
          @supervisors="updateSupervisors"
        )
  
  b-row(v-if="isLoading || isLoadingConf")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          :color="colors.primary"
        )

  div(v-else)
    b-row(v-if="!workers")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_workers_found') }}

    div(v-else)
      b-row(v-if="!commitment_functionality")
        b-col(md="6")
          support-tracker-fixed(v-if="dataForCharts.totalConfirmations",:dataForCharts="dataForCharts")
        b-col(md="6")
          bar-chart-progress-fixed(v-if="dataForCharts.totalConfirmations", :progress-data="progressCompliance")
      
      //- b-row
        b-col(cols="12")
          revenue-report(
            v-if="globalCompliance.labels && globalCompliance.labels.length",
            :location-data="globalCompliance"
          )

      b-row(v-if="!commitment_functionality")
        b-col(cols="12")
          bar-chart-compliance-fixed(
            v-if="locationData",
            :locationData="locationData"
          )

      b-row
        b-col(cols="12")
          heat-map-confirmations(
            v-if="confirmationsPerDay.length",
            :confirmations-per-day-data="confirmationsPerDay"
            :confirmation-total="confirmationTotal"
            :xLabelColors="xLabelColors"
            :yLabelColors="yLabelColors"
          )

      //- b-row
        b-col(
          md="6",
          v-for="(location, name, index) in locationCompliance",
          :key="index"
        )
          earnings-chart(:location-data="location")

      //- Confirmations table
      b-card(v-if="commitment_functionality")
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('message.tableHeader.date') }}
              th {{ $t('message.tableHeader.worker') }}
              th {{ $t('message.tableHeader.observer') }}
              th {{ $t('message.tableHeader.location') }}
              th {{ $t('message.tableHeader.process') }}
              th {{ $t('message.tableHeader.score') }}
              th {{ $t('message.tableHeader.total') }}
              th {{ $t('message.tableHeader.adherence') }}
              th {{ $t('message.tableHeader.action') }}
            b-tr(v-for="(confirmation, index) in confirmationsPaginated", :key="index")
              b-td {{ confirmation.dateString }}
              b-td {{ confirmation.worker }}
              b-td {{ confirmation.observer }}
              b-td {{ confirmation.peak }}
              b-td {{ confirmation.process }}
              b-td {{ confirmation.score }}
              b-td {{ confirmation.total }}
              b-td {{ confirmation.adherenceText() }}
              b-td
                view-button(@clicked="router.push({name: 'habit-confirmation-view', params: {id: confirmation.id}})")
                user-button(@clicked="router.push({name: 'apps-users-view', params: {id: confirmation.workerId}})")

        b-col.d-flex.justify-content-between.flex-wrap(cols='12')
          .mb-1
            b-form-group.mb-0
              label.d-inline-block.text-sm-left.mr-50 {{ $t('message.per_page') }}
              b-form-select#perPageSelect.w-50(
                v-model='perPageConf' 
                size='sm' 
                :options='[1,5,10,15,20,25,30,40,50,100]')
          .mb-1
            
            span.text-muted {{ showingMessageConf }}
          b-pagination(
            v-model='currentPageConf' 
            :total-rows='confirmations.length' 
            :per-page='perPageConf' 
            first-number='' 
            last-number='' 
            prev-class='prev-item' 
            next-class='next-item')

      //- Supervisors table
      b-card(v-else)
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('message.tableHeader.supervisor') }}
              th {{ $t('message.tableHeader.location') }}
              th {{ $t('message.tableHeader.cumplimiento') }}
              th {{ $t('message.tableHeader.observed') }}
              th {{ $t('message.tableHeader.pending') }}
              th {{ $t('message.tableHeader.meta') }}
            b-tr(v-for="(worker, index) of workers", :key="index")
              b-td {{ worker.name }}
              b-td {{ worker.locationsData }}
              b-td {{ worker.compliance }}
              b-td {{ worker.confirmationsCount }}
              b-td {{ worker.pending }}
              b-td {{ worker.meta }}

        b-col.d-flex.justify-content-between.flex-wrap(cols='12')
          .mb-1
            b-form-group.mb-0
              label.d-inline-block.text-sm-left.mr-50 {{ $t('message.per_page') }}
              b-form-select#perPageSelect.w-50(
                v-model='perPage' 
                size='sm' 
                :options='[1,5,10,15,20,25,30,40,50,100]')
          .mb-1
            
            span.text-muted {{ showingMessage }}
          b-pagination(
            v-model='currentPage' 
            :total-rows='totalWorkers' 
            :per-page='perPage' 
            first-number='' 
            last-number='' 
            prev-class='prev-item' 
            next-class='next-item')

      b-button(class="mr-4 mb-1", :disabled="!workers.length")
        json-excel(:data="workersForExcel", :fields="supervisorFields")
          | {{ $t('download_xlsx_compliance') }}
      b-button(class="mr-4 mb-1", :disabled="!confirmations.length")
        json-excel(:data="confirmations", :fields="excelFields")
          | {{ $t('download_xlsx_confirmations') }}
      b-button(class="mr-4 mb-1", :disabled="!deletedEvents.length")
        json-excel(:data="deletedEvents", :fields="deletedEventsFields")
          | {{ $t('download_xlsx_deleted_confirmations') }}
</template>

<script>
import { queryConfirmationsScore } from "@/@core/queries/confirmations"
import { confirmationsForWorkers } from "@/@core/queries/workers"
import { onMounted, ref, computed, watch } from "@vue/composition-api/dist/vue-composition-api"
import axios from "@axios"
import useNotifications from "@/composables/useNotifications"
import { BTable, BTableSimple, BThead, BTr, BTd, BCard, BPagination } from "bootstrap-vue"
import JsonExcel from "vue-json-excel"
import SupportTrackerFixed from "../charts/CardAnalyticSupportTrackerFixed.vue"
import BarChartProgressFixed from '../charts/ChartjsHorizontalBarChartProgressFixed.vue'
// import EarningsChart from "../charts/CardAnalyticEarningsChart.vue";
// import RevenueReport from "../charts/CardAnalyticRevenueReport.vue";
import BarChartComplianceFixed from '../charts/ChartjsBarChartComplianceFixed.vue'
import Filters from "@/views/organization/Filters.vue"
import i18n from '@/libs/i18n'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import store from "@/store"
import useCommon from '@/views/organization/useCommon'
import { get } from 'idb-keyval'
import { complianceCalculationFixedNumber } from '@/constants'
import HeatMapConfirmations from '../charts/ApexHeatMapConfirmations.vue'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import UserButton from '@/views/components/Shared/Buttons/UserButton.vue'
import { useRouter } from '@core/utils/utils'
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    BPagination,
    JsonExcel,
    SupportTrackerFixed,
    BarChartProgressFixed,
    // EarningsChart,
    // RevenueReport,
    BarChartComplianceFixed,
    Filters,
    Loading,
    HeatMapConfirmations,
    ViewButton,
    UserButton,
  },
  props: {
    fixedCalculation: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const userData = store.state?.userStore?.userData;
    const userRole = userData.role;
    const userId = userData.worker_id != null ? userData.worker_id.$oid : null
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const userLocations = userData.locations?.length ? userData.locations : null;
    const { getItemsWithAggregate, ObjectId, invokeFunction } = realmConnection()
    const { handleError } = useCommon()
    const { router } = useRouter()
    const qEvents = ref(0)
    const confirmations = ref([]);
    const confirmationsPaginated = ref([]);
    const supervisors = ref([]);
    const deletedEvents = ref([])
    const globalCompliance = ref({});
    const locationCompliance = ref({});
    const progressCompliance = ref({});
    const locationData = ref({})
    const trackerData = ref({});
    const locationFilter = ref(userLocations ? userLocations.map(e => e.value) : []);
    const roleFilter = ref([]);
    const processFilter = ref([]);
    const supervisorFilter = ref([]);
    const zoneFilter = ref([]);
    const agencyFilter = ref([]);
    const supervisorsDropdown = ref([])
    get('supervisors').then(response => {
      if (response) supervisorsDropdown.value = response
      else if (localStorage.supervisors) supervisorsDropdown.value = JSON.parse(localStorage.supervisors)
    })
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
    const confirmationFields = ref({})
    const isLoading = ref(true)
    const isLoadingConf = ref(true)

    const perPage = ref(50)
    const perPageConf = ref(10)
    const currentPage = ref(1)
    const currentPageConf = ref(1)
    const totalWorkers = ref(0)
    const dataWorkers = ref([])

    const workers = ref([])
    const workersForExcel = ref([])
    const dataForCharts = ref({})
    const confirmationsPerDay = ref([])
    const xLabelColors = ref([])
    const yLabelColors = ref([])

    const excelFields = ref({})
    const confirmationTotal = ref(0) 

    const { zone_agency_filter, default_language, commitment_functionality } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const { processAdherence, getDatesFromRange, dateDiffInDays, updateLocationConditions, getItemsPaginated } = useCommonDashboards()

    const supervisorFields = ref({
      [i18n.t('message.tableHeader.supervisor')]: "name",
      [i18n.t('message.tableHeader.location')]: "locationsData",
      [i18n.t('message.tableHeader.cumplimiento')]: "compliance",
      [i18n.t('message.tableHeader.observed')]: "confirmationsCount",
      [i18n.t('message.tableHeader.pending')]: "pending",
      [i18n.t('message.tableHeader.meta')]: "meta",
    })
    const deletedEventsFields = ref({
      [i18n.t('message.tableHeader.date')]: "startString",
      [i18n.t('message.tableHeader.worker')]: "worker",
      [i18n.t('message.tableHeader.location')]: "location",
      [i18n.t('message.tableHeader.process')]: "process",
      [i18n.t('message.tableHeader.status')]: "status",
      [i18n.t('message.tableHeader.deletedBy')]: "deletedBy",
      [i18n.t('message.tableHeader.deletedJustification')]: "deletedJustification",
      [i18n.t('message.tableHeader.id')]: "id",
    })
    
    const showingMessage = computed(() => {
      const from = ((currentPage.value * perPage.value) - perPage.value) + (dataWorkers.value.length ? 1 : 0)
      const to = dataWorkers.value.length + ((currentPage.value * perPage.value) - perPage.value)

      return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalWorkers.value })
    })

    const showingMessageConf = computed(() => {
      const from = ((currentPageConf.value * perPageConf.value) - perPageConf.value) + (confirmationsPaginated.value.length ? 1 : 0)
      const to = confirmationsPaginated.value.length + ((currentPageConf.value * perPageConf.value) - perPageConf.value)

      return i18n.tc('message.paginationText', 0, { from: from, to: to, total: confirmations.value.length })
    })

    watch([currentPage, perPage], () => {
      listWorkers()
    })

    watch([currentPageConf, perPageConf], () => {
      confirmationsPaginated.value = getItemsPaginated(confirmations.value, currentPageConf.value, perPageConf.value)
    })

    // watch([qEvents], () =>{
    //   currentPage.value = 1
    // })

    const listConfirmations = async () => {
      isLoadingConf.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      try {
        const initialQuery = {
          client_id: ObjectId(clientId),
          date: { $gte: startFilter, $lt: endFilter },
          pending: { $ne: true }
        }

        if (processFilter.value.length > 0) {
          initialQuery.process = { $in: processFilter.value.map(e => ObjectId(e)) }
        }

        if (supervisorFilter.value.length > 0) {
          initialQuery.supervisor = { $in: supervisorFilter.value.map(e => ObjectId(e)) }
        }

        const finalQuery = {}

        if (roleFilter.value.length > 0) {
          finalQuery['supervisor.roles'] = { $in: roleFilter.value.map(e => ObjectId(e)) }
        }

        if (userRole === 'supervisor') {
          finalQuery.$or = [
            { 'supervisor._id': userId },
            { 'supervisor.supervisors': ObjectId(userId) }
          ];
        }

        if (locationFilter.value.length > 0) {
          finalQuery['supervisor.locations._id'] = { $in: locationFilter.value }
        }

        if (zoneFilter.value.length > 0) {
          finalQuery['supervisor.locations.zone'] = { $in: zoneFilter.value }
        }

        if (agencyFilter.value.length > 0) {
          finalQuery['supervisor.locations.agency'] = { $in: agencyFilter.value }
        }

        const pipeline = [
          { $match: initialQuery },
          {
            $lookup: {
              from: 'worker',
              localField: 'supervisor',
              foreignField: '_id',
              pipeline: [
                { $project: { name: 1, roles: 1, supervisors: 1, locations: 1 } },
                {
                  $lookup: {
                    from: 'location',
                    localField: 'locations',
                    foreignField: '_id',
                    pipeline: [ { $project: { location: 1, zone: 1, agency: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                    as: 'locations',
                  },
                },
                { $addFields: { _id: { $toString: "$_id" } } }
              ],
              as: 'supervisor'
            }
          },
          { $match: finalQuery },
          {
            $lookup: {
              from: 'process',
              localField: 'process',
              foreignField: '_id',
              pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'process'
            }
          },
          {
            $lookup: {
              from: 'worker',
              localField: 'worker',
              foreignField: '_id',
              pipeline: [
                { $project: { name: 1, locations: 1 } },
                {
                  $lookup: {
                    from: 'location',
                    localField: 'locations',
                    foreignField: '_id',
                    pipeline: [ { $project: { location: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                    as: 'locations',
                  },
                },
                { $addFields: { _id: { $toString: "$_id" } } }
              ],
              as: 'worker'
            }
          },
          { $addFields: { _id: { $toString: "$_id" }, supervisor: { $arrayElemAt: ["$supervisor", 0] }, process: { $arrayElemAt: ["$process", 0] }, worker: { $arrayElemAt: ["$worker", 0] } } }
        ]
      
        const items = await getItemsWithAggregate({ collection: 'confirmation', pipeline })
        
        parseConfirmationData(items, startFilter)
      } catch (error) {
        console.log(error);
        handleError({ error, defaultMessage: i18n.t('message.err_confirmation_list') })
      } finally {
        isLoadingConf.value = false
      }
    }

    const parseConfirmationData = (items, startFilter) => {
      excelFields.value = {
          [i18n.t('message.tableHeader.date')]: "dateString",
          [i18n.t('message.tableHeader.worker')]: "worker",
          [i18n.t('message.tableHeader.observer')]: "observer",
          [i18n.t('message.tableHeader.location')]: "location",
          [i18n.t('message.tableHeader.process')]: "process",
          [i18n.t('message.tableHeader.score')]: "score",
          [i18n.t('message.tableHeader.total')]: "total",
          [i18n.t('message.tableHeader.adherence')]: "adherence",
          [i18n.t('message.tableHeader.duration')]: "duration",
          [i18n.t('message.tableHeader.comments')]: "comments"
        }

        // Change the dates so that it matches the UTC time zone
        const timezoneOffsetHours = startFilter.getTimezoneOffset() / 60

        let confirmationData = []
        let adherenceData = { score: 0, total: 0 }
        let dailySupervisorData = {}
        let confirmedProcessesUnsorted = { _total: 0 }

        // Set up processes object for heat map
        for (const confirmation of items) {
          if (confirmation.total) {
            const process = confirmation.process?.name
            if (!confirmedProcessesUnsorted.hasOwnProperty(process)) {
              confirmedProcessesUnsorted[process] = { confirmationsObserved: 0, id: confirmation.process?._id }
            }
          }
        }

        // Sort processes object
        const confirmedProcessesSortedMap = new Map(Object.entries(confirmedProcessesUnsorted).sort());
        const confirmedProcesses = Object.fromEntries(confirmedProcessesSortedMap)

        // Set up supervisors object with processes object
        dataWorkers.value.forEach(e => {
          dailySupervisorData[e.name] = JSON.parse(JSON.stringify(confirmedProcesses))
          dailySupervisorData[e.name]._id = e._id?.toString()
        })

        for (const confirmation of items) {
          if (confirmation.total) {
            const date = confirmation.date
            date.setHours(date.getHours() + timezoneOffsetHours)
            const dateString = date.toLocaleDateString(`${default_language || 'en'}-US`)
            const supervisor = confirmation.supervisor?.name
            const process = confirmation.process?.name
            const score = confirmation.score
            const total = confirmation.total
            let comments = ""
            if (confirmation?.activities[confirmation.activities?.length - 1]) {
              for (let comentario in confirmation.activities[confirmation.activities.length - 1]?.behaviours) {
                if (confirmation.activities[confirmation.activities.length - 1].behaviours[comentario].type === "text") {
                  comments = confirmation.activities[confirmation.activities.length - 1].behaviours[comentario].answer
                }
              }
            }
            let payload = {
              date,
              dateString,
              worker: confirmation.worker?.name,
              observer: supervisor,
              location: confirmation.supervisor?.locations?.map(e => e.location).join(" / "),
              process,
              score: score,
              total: total,
              adherence: processAdherence(score, total),
              adherenceText: function() {
                if (this.total === 0) return "-"
                return `${this.adherence}%`
              },
              duration: confirmation.duration,
              id: confirmation._id,
              workerId: confirmation.worker?._id,
              comments: comments
            }

            // Add metadata fields if applicable
            if (confirmation.metadata) {
              confirmation.metadata.forEach(e => {
                payload[e.name] = e.answer
                if (!excelFields.value.hasOwnProperty(i18n.t(`metadata.${e.name}`))) {
                  excelFields.value[i18n.t(`metadata.${e.name}`)] = e.name
                }
              })
            }
            
            // Data for heat map
            if (dailySupervisorData.hasOwnProperty(supervisor)) {
              dailySupervisorData[supervisor][process].confirmationsObserved++
              dailySupervisorData[supervisor]._total++
            }
            
            confirmationData.push(payload)
            adherenceData.score += score
            adherenceData.total += total
          }
        }
        confirmationData.sort(function (a, b) {
          if (b.score === 0 && a.score === 0) return b.total - a.total
          return a.adherence - b.adherence
        })

        // Heat map
        const heatMapData = []

        for (const supervisor in dailySupervisorData) {
          const heatMapSupervisorData = {
            name: supervisor,
            data: [],
            total: dailySupervisorData[supervisor]._total,
            supervisorId: dailySupervisorData[supervisor]._id
          }

          delete dailySupervisorData[supervisor]._total
          delete dailySupervisorData[supervisor]._id

          for (const process in dailySupervisorData[supervisor]) {
            heatMapSupervisorData.data.push({
              x: process,
              y: dailySupervisorData[supervisor][process].confirmationsObserved,
              processId: dailySupervisorData[supervisor][process].id,
            })
          }

          heatMapData.push(heatMapSupervisorData)
        }

        // Calculate total per supervisor
        heatMapData.forEach(supervisorData => {
          let totalSupervisor = 0
          supervisorData.data.forEach(data => {
            totalSupervisor += data.y
          })
          supervisorData.data.push({
            x: i18n.t('tooltip.total_per_supervisor'),
            y: totalSupervisor
          })
        })
        
        // Calculate total per process
        const processDataMap = {}
        heatMapData.forEach(supervisorData => {
          supervisorData.data.forEach(data => {
            const { x, y, processId } = data;
            processDataMap[x] ? processDataMap[x].value += y : processDataMap[x] = { id: processId, value: y }
          })
        })
        const totalProcess = {
          data: [],
          name: i18n.t('tooltip.total_per_process'),
          supervisorId: '',
          total: 0,
        }
        for (const process in processDataMap) {
          totalProcess.data.push({
            x: process,
            y: processDataMap[process].value,
            processId: processDataMap[process].id
          })
        }
        heatMapData.push(totalProcess)

        // Sort heat map data
        heatMapData.sort(function(a, b) {
          if (a.name === 'Total') return -1
          if (b.name === 'Total') return 1
          if (b.total === a.total) {
            if (a.name > b.name) return -1
            if (a.name < b.name) return 1
            return 0
          }    
          return a.total - b.total
        })

        // Define label color for totals
        const cantLabelY = heatMapData.length
        const cantLabelX = heatMapData[0].data.length
        xLabelColors.value = []
        yLabelColors.value = []
        for (let i = 0; i < cantLabelX; i++) {
          i === cantLabelX - 1 ? xLabelColors.value.push('#4e8769') : xLabelColors.value.push('#373d3f');          
        }
        for (let i = 0; i < cantLabelY; i++) {
          i === 0 ? yLabelColors.value.push('#4e8769') : yLabelColors.value.push('#373d3f')
        }

        // Update variables to display
        confirmationsPerDay.value = heatMapData
        confirmations.value = confirmationData
        confirmationsPaginated.value = getItemsPaginated(confirmationData, currentPageConf.value, perPageConf.value)
    }

    const listWorkers = async () => {
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)
      isLoading.value = true

      const query = {
        limit: perPage.value,
        total: currentPage.value === 1,
        offset: (currentPage.value * perPage.value) - perPage.value,
        userId: userId,
        privileges: userRole,
        client_id: clientId,
        date_gte: startFilter,
        date_lt: endFilter,
        locations: locationFilter.value,
        roles: roleFilter.value,
        processes: processFilter.value,
        supervisors: supervisorFilter.value,
      }

      try {
        const items = await invokeFunction({ name: 'confirmationsForWorkers', arg: query })
        const workersInside = items?.workers || []
        const workersPagination = items?.workersWithPagination || []
        qEvents.value = workersPagination.length
        dataWorkers.value = workersPagination
        listConfirmations()
        parseWorkersData(workersInside, workersPagination, startFilter, endFilter)
      } catch (error) {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.err_confirmation_list') })
      } finally {
        isLoading.value = false
      }
    }

    const parseWorkersData = (workersInside, workersPagination, startFilter, endFilter) => {
      for(let element of workersPagination){
        if (!props.fixedCalculation){
          if (element.rolesData[0]?.numEventsRequired){
            if (Math.floor(dateDiffInDays(startFilter, endFilter)) <= 31) element.meta = element.rolesData[0].numEventsRequired
            else if (Math.floor(dateDiffInDays(startFilter, endFilter)) <= 62 && Math.floor(dateDiffInDays(startFilter, endFilter)) > 31) element.meta = (element.rolesData[0].numEventsRequired)*2
            else element.meta = (element.rolesData[0].numEventsRequired) *3
            if(element.confirmationsCount <= 0){
              element.pending = Math.floor((dateDiffInDays(startFilter, endFilter) * parseInt(element.rolesData[0].numEventsRequired)) / 30)
            }
            else if(element.confirmationsCount >= Math.floor((dateDiffInDays(startFilter, endFilter) * parseInt(element.rolesData[0].numEventsRequired)) / 30) ){
              element.pending = 0
            } else {
              element.pending = Math.floor((dateDiffInDays(startFilter, endFilter) * parseInt(element.rolesData[0].numEventsRequired)) / 30) - element.confirmationsCount;
            }
          } else {
            element.meta = 0
            element.pending = 0
          }
        } else {
          if (Math.floor(dateDiffInDays(startFilter, endFilter)) <= 31) element.meta = complianceCalculationFixedNumber
          else if (Math.floor(dateDiffInDays(startFilter, endFilter)) <= 62 && Math.floor(dateDiffInDays(startFilter, endFilter)) > 31) element.meta = (complianceCalculationFixedNumber)*2
          else element.meta = (complianceCalculationFixedNumber) *3
          if(element.confirmationsCount <= 0){
            element.pending = Math.floor(dateDiffInDays(startFilter, endFilter) / 2)
          }
          else if(element.confirmationsCount >= Math.floor(dateDiffInDays(startFilter, endFilter) / 2)){
            element.pending = 0
          }
          else {
            element.pending = Math.floor(dateDiffInDays(startFilter, endFilter) / 2) - element.confirmationsCount;
          }
        }
        if (!props.fixedCalculation){
          if (element.rolesData[0]?.numEventsRequired){
            if(element.pending === 0 && element.rolesData[0].numEventsRequired){
              element.compliance = Number((element.confirmationsCount / element.meta)*100).toFixed(1)
              if(element.compliance === "0.0") element.compliance = 0.0
              element.compliance = `${element.compliance}%`
            } 
            else if(element.confirmationsCount <= 0) {
              element.compliance = `${0}%`
            }
            else {
              element.compliance = Number((element.confirmationsCount / element.meta)*100).toFixed(1)
              if(element.compliance === "100.0") element.compliance = 100
              element.compliance = `${element.compliance}%`
            }
          } else {
            if(element.pending === 0){
              element.compliance = Number((element.confirmationsCount / 1)*100).toFixed(1)
              if(element.compliance === "0.0") element.compliance = 0.0
              element.compliance = `${element.compliance}%`
            } 
            else if(element.confirmationsCount <= 0) {
              element.compliance = `${0}%`
            }
            else {
              element.compliance = Number((element.confirmationsCount / 1)*100).toFixed(1)
              if(element.compliance === "100.0") element.compliance = 100
              element.compliance = `${element.compliance}%`
            }
          }
        } else {
          if(element.pending === 0){
              element.compliance = Number((element.confirmationsCount / element.meta)*100).toFixed(1)
              if(element.compliance === "0.0") element.compliance = 0.0
              element.compliance = `${element.compliance}%`
            } 
            else if(element.confirmationsCount <= 0) {
              element.compliance = `${0}%`
            }
            else {
              element.compliance = Number((element.confirmationsCount / element.meta)*100).toFixed(1)
              if(element.compliance === "100.0") element.compliance = 100
              element.compliance = `${element.compliance}%`
            }
        }

      }

      const WorkersPaginationLocation = workersPagination.map(worker => {
        let location = ""       
        if(worker.locationsData.length >= 2){
          location = worker.locationsData.map(location => location.location).join(" / ")
        } else {
          location = worker.locationsData[0]?.location || "";
        }
        return {
          ...worker,
          locationsData: location
        };
      });

      workers.value = WorkersPaginationLocation

      let dataForSupportTracker = {}
      let supportTrackerTotal = 0
      let supportTrackerTotalDone = 0
      let supportTrackerTotalPending = 0
      let supportTrackerTotalMeta = 0
      let totalWorkersForPagination = 0

      for(let element of workersInside){
        totalWorkersForPagination = totalWorkersForPagination + 1
        if (!props.fixedCalculation) {
          if (element.rolesData[0]?.numEventsRequired) {
            if (Math.floor(dateDiffInDays(startFilter, endFilter)) <= 31) element.meta = element.rolesData[0].numEventsRequired
            else if (Math.floor(dateDiffInDays(startFilter, endFilter)) <= 62 && Math.floor(dateDiffInDays(startFilter, endFilter)) > 31) element.meta = (element.rolesData[0].numEventsRequired)*2
            else element.meta = (element.rolesData[0].numEventsRequired) *3

            if(element.confirmationsCount <= 0){
              element.pending = Math.floor((dateDiffInDays(startFilter, endFilter) * parseInt(element.rolesData[0].numEventsRequired)) / 30)
            }
            else if(element.confirmationsCount >= Math.floor((dateDiffInDays(startFilter, endFilter) * parseInt(element.rolesData[0].numEventsRequired)) / 30) ){
              element.pending = 0
            } else {
              element.pending = Math.floor((dateDiffInDays(startFilter, endFilter) * parseInt(element.rolesData[0].numEventsRequired)) / 30) - element.confirmationsCount;
            }
          } else {
            element.meta = 0
            element.pending = 0
          }
        } else {
          if (Math.floor(dateDiffInDays(startFilter, endFilter)) <= 31) element.meta = complianceCalculationFixedNumber
          else if (Math.floor(dateDiffInDays(startFilter, endFilter)) <= 62 && Math.floor(dateDiffInDays(startFilter, endFilter)) > 31) element.meta = (complianceCalculationFixedNumber)*2
          else element.meta = (complianceCalculationFixedNumber) *3
          if(element.confirmationsCount <= 0){
            element.pending = Math.floor(dateDiffInDays(startFilter, endFilter) / 2)
          }
          else if(element.confirmationsCount >= Math.floor(dateDiffInDays(startFilter, endFilter) / 2)){
            element.pending = 0
          }
          else {
            element.pending = Math.floor(dateDiffInDays(startFilter, endFilter) / 2) - element.confirmationsCount;
          }
        }
        
        if (!props.fixedCalculation) {
          if (element.rolesData[0]?.numEventsRequired) {
            if(element.pending === 0){
              element.compliance = Number((element.confirmationsCount / 1)*100).toFixed(1)
              if(element.compliance === "0.0") element.compliance = 0.0
              element.compliance = `${element.compliance}%`
            } 
            else if(element.confirmationsCount <= 0) {
              element.compliance = `${0}%`
            }
            else {
              element.compliance = Number((element.confirmationsCount / element.meta)*100).toFixed(1)
              if(element.compliance === "100.0") element.compliance = 100
              element.compliance = `${element.compliance}%`
            }
          } else {
            if(element.pending === 0){
              element.compliance = Number((element.confirmationsCount / 1)*100).toFixed(1)
              if(element.compliance === "0.0") element.compliance = 0.0
              element.compliance = `${element.compliance}%`
            } 
            else if(element.confirmationsCount <= 0) {
              element.compliance = `${0}%`
            }
            else {
              element.compliance = Number((element.confirmationsCount / 1)*100).toFixed(1)
              if(element.compliance === "100.0") element.compliance = 100
              element.compliance = `${element.compliance}%`
            }
          }
        } else {
          if(element.pending === 0){
              element.compliance = Number((element.confirmationsCount / element.meta)*100).toFixed(1)
              if(element.compliance === "0.0") element.compliance = 0.0
              element.compliance = `${element.compliance}%`
            } 
            else if(element.confirmationsCount <= 0) {
              element.compliance = `${0}%`
            }
            else {
              element.compliance = Number((element.confirmationsCount / element.meta)*100).toFixed(1)
              if(element.compliance === "100.0") element.compliance = 100
              element.compliance = `${element.compliance}%`
            }
        }
        supportTrackerTotalDone = supportTrackerTotalDone + element.confirmationsCount
        supportTrackerTotalPending = supportTrackerTotalPending + element.pending
        supportTrackerTotalMeta = supportTrackerTotalMeta + element.meta
        supportTrackerTotal =  supportTrackerTotalDone + supportTrackerTotalPending
      }

      if (currentPage.value === 1) totalWorkers.value = totalWorkersForPagination

      dataForSupportTracker.totalConfirmations = supportTrackerTotal
      dataForSupportTracker.totalDone = supportTrackerTotalDone
      dataForSupportTracker.totalPending = supportTrackerTotalPending
      dataForSupportTracker.totalMeta = supportTrackerTotalMeta

      dataForCharts.value = dataForSupportTracker


      workersInside.sort((a, b) => {
        const complianceA = parseFloat(a.compliance);
        const complianceB = parseFloat(b.compliance);

        return complianceA - complianceB;
      });
      
      // complianceTransform(workers)

      const actualProgress = dataForCharts.value.totalConfirmations
        ? Math.round((dataForCharts.value.totalDone / dataForCharts.value.totalMeta) * 100)
        : 0

      const theoreticalProgress = endFilter < now
        ? 100
        : startFilter > now
          ? 0
          : Math.round(dateDiffInDays(startFilter, now) / dateDiffInDays(startFilter, endFilter) * 100)

      progressCompliance.value = {
        actual: actualProgress,
        theoretical: theoreticalProgress,
        totalDone: dataForSupportTracker.totalDone
      }

      const barChartData = {
        location: [],
        totalDone: [],
        totalPending: [],
        totalMeta: [],
        compliance: [],
      };

      const locationMap = {};

      for (const worker of workersInside) {
        // let location = worker.locationsData[0]?.location || "";
        for(const element of worker.locationsData){
          if(locationFilter.value.length && !locationFilter.value.includes(element._id?.toString())){
            continue
          }
          let name = element.location
          if(locationMap.hasOwnProperty(name)){
            locationMap[name] = {
              totalDone: locationMap[name].totalDone + worker.confirmationsCount,
              pending: locationMap[name].pending + worker.pending,
              meta: locationMap[name].meta + worker.meta,
              compliance: ((locationMap[name].totalDone / locationMap[name].meta)*100).toFixed(1),
              id: locationMap[name].id
            }
          } else {
            locationMap[name] = {
              totalDone: worker.confirmationsCount,
              pending: worker.pending,
              meta: worker.meta,
              compliance: worker.compliance,
              id: element._id?.toString()
            }
          }
        }
      }

      let locationComplianceDataSorted = Object.entries(locationMap)

      let locationComplianceLabels = []
      let locationComplianceValues = []
      let locationComplianceValuesDone = []
      let locationComplianceValuesPending = []
      let locationComplianceValuesMeta = []
      let locationIdValues = []

      locationComplianceDataSorted.forEach(e => {
        locationComplianceLabels.push(e[0])
        locationComplianceValuesDone.push(e[1].totalDone)
        locationComplianceValuesPending.push(e[1].pending)
        locationComplianceValuesMeta.push(e[1].meta)
        let compliance = ((e[1].totalDone / e[1].meta)*100).toFixed(1)
        locationComplianceValues.push(compliance)
        locationIdValues.push(e[1].id)
      })

      barChartData.location = locationComplianceLabels
      barChartData.totalDone = locationComplianceValuesDone
      barChartData.totalPending = locationComplianceValuesPending
      barChartData.totalMeta = locationComplianceValuesMeta
      barChartData.compliance = locationComplianceValues
      
      const complianceIndices = barChartData.compliance.map((_, index) => index);
      complianceIndices.sort((a, b) => {
        return parseFloat(barChartData.compliance[a]) - parseFloat(barChartData.compliance[b]);
      });
      barChartData.location = complianceIndices.map(index => barChartData.location[index]);
      barChartData.totalDone = complianceIndices.map(index => barChartData.totalDone[index]);
      barChartData.totalPending = complianceIndices.map(index => barChartData.totalPending[index]);
      barChartData.totalMeta = complianceIndices.map(index => barChartData.totalMeta[index]);
      barChartData.compliance = complianceIndices.map(index => barChartData.compliance[index]);
      barChartData.additionalInfo = {
        locationIds: locationIdValues
      }
      
      locationData.value = barChartData

      const modifiedWorkersInside = workersInside.map(worker => {
        let location = ""       
        if(worker.locationsData.length >= 2){
          location = worker.locationsData.map(location => location.location).join(" / ")
        } else {
          location = worker.locationsData[0]?.location || "";
        }
        return {
          ...worker,
          locationsData: location
        };
      });

      workersForExcel.value = modifiedWorkersInside
    }

    const compliance = (supervisor) => {
      const total = supervisor.Pendientes;
      if (!total) return "N/A"
      const ratio = supervisor.Observadas < total
        ? (supervisor.Observadas / total) * 100
        : 100

      return `${ratio.toFixed(1)}%`;
    }

    const updateFilter = (data) => {
      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;
      processFilter.value = data.processFilter;
      supervisorFilter.value = data.supervisorFilter;
      dateRangeFilter.value = data.dateRangeFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;

      listWorkers();
      currentPage.value = 1
      currentPageConf.value = 1
    }

    const updateSupervisors = (data) => {
      if (!supervisorsDropdown.value.length) {
        supervisorsDropdown.value = data;
        listWorkers();
      }
    }

    onMounted(() => {
      listWorkers()
    })

    return {
      confirmations,
      supervisors,
      compliance,
      trackerData,
      locationCompliance,
      globalCompliance,
      progressCompliance,
      updateFilter,
      updateSupervisors,
      confirmationFields,
      supervisorFields,
      isLoading,
      qEvents,
      zone_agency_filter,
      deletedEvents,
      deletedEventsFields,
      perPage,
      currentPage,
      totalWorkers,
      showingMessage,
      workers,
      dataForCharts,
      locationData,
      workersForExcel,
      excelFields,
      confirmationsPerDay,
      commitment_functionality,
      router,
      perPageConf,
      currentPageConf,
      showingMessageConf,
      confirmationsPaginated,
      isLoadingConf,
      xLabelColors,
      yLabelColors,
      confirmationTotal,
      colors
    };
  },
};
</script>